<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Edit Pendaftaran atau Perjanjian'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'appointments/' + form.id"
              :purpose="'edit'"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/appointments/Form.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {
        room_id: "",
        patient_id: "",
        doctor_id: "",
        action_type_id: "",
        date: "",
        doctor_time_slot_id: "",
        live_meeting_link: "",
        appointment_status_id: "",
        notes: "",
      },
    };
  },

  methods: {
    async get() {
      this.form = await module.get("appointments/" + this.$route.params.id);
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/appointment/list/0");
      } else {
        this.form["_method"] = "put";
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pendaftaran atau Perjanjian" },
      { title: "Daftar atau Buat Janji" },
    ]);
    // Get Data
    this.get();
  },
};
</script>

<style></style>
